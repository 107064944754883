@import '../../styles/variables.scss';

.Warning {
    margin: 1px 3px;
    padding: 5px 11px;
    background-color: $warn_bg_color;
    margin-bottom: 13px;
    border-radius: 3px;
    border: 1px solid $warn_border_color;
    text-align: left;
    color: $warn_color;
    padding-left: 5px;
    font-size: 0.9em;
    animation: fadeIn 0.5s;
    
    p {
        margin-left: 1px;
        display: table-cell;
    }
    i {
        padding: 3px;
        display: table-cell;
        vertical-align: middle;
        padding-right: 7px;
    }
}