.BrowserHandler {
    padding: 10px;
    h1 {
        font-size: 2em;
    }
    p {
        font-size: 1.2em;
    }
    &__options {
        display: flex;
        align-items: center;
    }
}