@import '../../styles/variables.scss';

.List {
    padding: 3px;
    margin: 0;
    display: flex;

    label {
        padding: 3px;
        font-size: 0.9em;
    }

    label, ul {
        display: inline-flex;
        flex-direction: row;
        min-width: 150px;
    }

    ul{

        li {
            display: inline-block;
            background: $button_color;
            padding: 7px 15px;
            border-left: 1px solid $button_border_color;
            border-radius: 5px;
            color: $font_color;
            margin: 3px;
            cursor: pointer;
            font-size: 0.9em;
        }
    
        &__selected {
            background: $form_box_bg_color;
        }

    }
}
