.Overview {
    padding: 0px;
    h2 {
        padding: 0px;
        font-size: 130%;
        margin-bottom: 15px;
        margin-left: 7px;
        small {
            font-size: x-small;
            vertical-align: middle;
        }
    }
    &__section {
        &__group {
            font-style: italic;
            font-size: 95%;
            display: flex;                
            &__title {
                padding: 3px;
                flex: 1;
            }
            &__count {
                padding: 0px;
            }

            &__copy {
                cursor: pointer;
            }
        }
        &__groupbold {
            font-weight: bold;
        }
    }
    p {
        margin: 10px;
        padding: 7px 1px;
        border-bottom: 1px solid var(--week-color-bg);
        margin-bottom: 15px;
    }

    &__packages {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &__package {
            flex: 1;
            margin: 5px;
            text-align: center;
            position: relative;

            &__icon {
                padding: 1px;
                position: relative;
                margin: -10px;
                margin-bottom: 10px;

                img {
                    width:100%;
                    height:100%;
                    object-fit: cover;
                    overflow: hidden;
                }
            }
            &__summary {
                padding: 5px;
                color: var(--dark-color);
                margin-top: 3px;
                font-size: 93%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                margin-bottom: 11px;
            }

            &__title {
                font-weight: bold;
                color: var(--link-color);
            }

            &__entitled {
                background-color: #2f902f;
                padding: 5px;
                position: absolute;
                left: 0;
                bottom: 0;
                margin-bottom: 0px;
                border-top-right-radius: 11px;
                border-bottom-left-radius: 2px;
                font-size: 11px;
                text-transform: uppercase;
                padding-right: 9px;
                padding-bottom: 3px;
            }
        }
    }

}


@media only screen and (max-width : 700px) {
    .Overview {
    
        &__packages {
            display: block;
    
            &__package {
                flex: none;
                display: block;
                margin: 5px;
                text-align: center;
                position: relative;
    
                &__icon {
                    padding: 1px;
                    position: relative;
    
                    img {
                        width:100%;
                        height:100%;
                        object-fit: cover;
                        overflow: hidden;
                    }
                }
            }
        }
    
    }
  }