@import "../../styles/variables.scss";

._msg {    
    padding: 35px 25px;
    text-align: center;
    font-size: 95%;
    border-radius: 3px;
    opacity: 0;
    animation: fadeIn 0.5s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
}
.AutoPopupMessage {
    position: fixed;
    top: 0;
    margin-top: 1vh;
    left: 0;
    display: flex;
    right: 0;
    z-index: 5;
    justify-content: center;
    
    &__message {
        @extend ._msg;
        background: rgb(66, 88, 65);
        color: whitesmoke;
    }
    
    &__alert {
        @extend ._msg;
        background: rgb(168, 83, 83);
        color: whitesmoke;
    }
}