@import '../../styles/global.scss';
@import '../../styles/variables.scss';

.SubMenu {
    list-style: none;
    display: flex;
    color: $font_color;
    font-weight: 500;
    background: $bg_color;
    margin-left: 5px;
    margin-right: 5px;
    overflow-y: auto;

    &__tab {
        display: inline-flex;
        padding: 15px;
        cursor: pointer;
        margin-left: 5px;
        border-radius: 5px;
        margin-right: 5px;
        white-space: nowrap;
    }

    &__selected {
        background: $side_bg_color;
        border: 1px solid #8080804a;
    }
}

    
.SubMenu::-webkit-scrollbar {height: 0.4em;} 
.SubMenu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 3px #8080802b; 
    border-radius: 5px;
    background-color: #5881330f;
} 
.SubMenu::-webkit-scrollbar-thumb {
    background-color: #588133a1;
    border-radius: 6px;
}
.SubMenu::-webkit-scrollbar-thumb:hover {background: #109eda; }

/* Phones and Tablets Sizes */ 
@media only screen and (max-width : 600px) {
    .SubMenu {
        font-weight: 500;
        margin-left: -10px;
        margin-right: 0px;
        width: auto;
        overflow-x: scroll;
        white-space: nowrap;
        overflow-x: auto;
    
        &__tab {
            margin-right: 5px;
        }
    }
}