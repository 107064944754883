
.CheckBox {
    padding: 3px;
    text-align: right;

    label {
        padding: 1px;
    }

    input {
        width: 25px;
        height: 25px;
        vertical-align: middle;
    }
}