//theme colors - dark means for dark theme and light for light theme
$light_contents_bg_color: #c7e1c7;
$dark_contents_bg_color: #252625;
$contents_bg_color: var(--contents-bg-color);

$light_link_color: #a2bda2;
$dark_link_color: #4c524c;
$link_color: var(--link-color);

$title_color: var(--title-color);
$dark_title_color: #899389;
$light_title_color: #525652;

$light_contents_border_color: #aac3aa;
$dark_contents_border_color: #3c423c;
$contents_border_color: var(--contents-border-color);

$dark_font_color: #9ea39e;
$light_font_color: #2c322c;
$font_color: var(--font-color);

$dark_side_bg_color: #1e221e;
$light_side_bg_color: #e8efe8;
$side_bg_color: var(--side-bg-color);

$dark_button_color: #385038;
$light_button_color: #8ca78c;
$button_color: var(--button-color);

$button_hover_color: var(--button-hover-color);
$light_button_hover_color: #799379;
$dark_button_hover_color:  #566456;

$dark_button_border_color: #4a604a;
$light_button_border_color: #778177;
$button_border_color: var(--button-border-color);

$warn_color: var(--warn-color);
$dark_warn_color: #af9f8a;
$light_warn_color: #5e574d;
$warn_bg_color: var(--warn-bg-color);
$dark_warn_bg_color: #3a3328;
$light_warn_bg_color: #cfb38b;
$warn_border_color: var(--warn-border-color);
$dark_warn_border_color: #4e391ad9;
$light_warn_border_color: #b59a74;

$form_box_bg_color: var(--form-box-bg-color);
$form_box_bg_color_dark: #161616;
$form_box_bg_color_light: #ffffff;
$form_box_border_color: var(--form-box-border-color);
$form_box_border_color_dark: #ffffff0a;
$form_box_border_color_light: #ffffff;
$form_box_color: var(--form-box-color);
$form_box_color_dark: #ffffff87;
$form_box_color_light: #706d6d;

$border_color: var(--border-color);
$border_color_dark: #f0f8ff1c;
$border_color_light: #f0f8ff;
$bg_color: var(--bg-color);
$bg_color_dark: #343333;
$bg_color_light: #f0f0f0;

$dark_week_color: #38503875;
$light_week_color: #7e7e7e;
$week_color: var(--week-color);
$dark_week_color_bg: #c0d3c075;
$light_week_color_bg: #71787159;
$week_color_bg: var(--week-color-bg);

$hover: #588133;
$hover_color: white;

$light: #f5fff5;
$dark: #0f0f0f;
$body_bg: var(--body-bg);

//assign theme colors based on the theme
body {
    --border-color: #{$border_color_light};
    --bg-color: #{$bg_color_light};

    --title-color: #{$light_title_color};
    --font-color: #{$light_font_color};
    --contents-bg-color: #{$light_contents_bg_color};
    --link-color: #2196F3;
    --contents-border-color: #{$light_contents_border_color};
    --side-bg-color: #{$light_side_bg_color};
    --button-color: #{$light_button_color};
    --button-hover-color: #{$light_button_hover_color};
    --button-border-color: #{$light_button_border_color};

    --warn-color: #{$light_warn_color};
    --warn-bg-color: #{$light_warn_bg_color};
    --warn-border-color: #{$light_warn_border_color};
    
    --form-box-color: #{$form_box_color_light};
    --form-box-bg-color: #{$form_box_bg_color_light};
    --form-box-border-color: #{$form_box_border_color_light};
    
    --week-color: #{$light_week_color};
    --week-color-bg: #{$light_week_color_bg};

    --body-bg: #{$light};

    --light-color: white;
    --dark-color: black;
    
    color: $light_font_color;
    background: var(--bg-color);
}

body.dark {
    --body-bg: #{$dark};
    --border-color: #{$border_color_dark};
    --bg-color: #{$bg_color_dark};

    --title-color: #{$dark_title_color};
    --font-color: #{$dark_font_color};
    --contents-bg-color: #{$dark_contents_bg_color};
    --link-color: #2196F3;
    --contents-border-color: #{$dark_contents_border_color};
    --side-bg-color: #{$dark_side_bg_color};
    --button-color: #{$dark_button_color};
    --button-hover-color: #{$dark_button_hover_color};
    --button-border-color: #{$dark_button_border_color};
    --warn-color: #{$dark_warn_color};

    --warn-color: #{$dark_warn_color};
    --warn-bg-color: #{$dark_warn_bg_color};
    --warn-border-color: #{$dark_warn_border_color};
    
    --form-box-color: #{$form_box_color_dark};
    --form-box-bg-color: #{$form_box_bg_color_dark};
    --form-box-border-color: #{$form_box_border_color_dark};
    
    --week-color: #{$dark_week_color};
    --week-color-bg: #{$dark_week_color_bg};
    
    --light-color: black;
    --dark-color: white;
    
    color: $light_font_color;
    background: var(--bg-color);
}
