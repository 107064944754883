.MainLayout {    
    &__container {
        margin-left: 185px;
    }
}

/* Phones and Tablets Sizes */ 
@media only screen and (max-width : 600px) {
    .MainLayout {    
        &__container {
            margin-left: 0px;
        }
    }
}