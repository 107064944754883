.RegisterPage {
    padding: 15px;

    &__title {
        font-size: 1.5em;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    &__bottomtext {
        padding: 5px;
        font-size: 0.9em;
        p {
            a {
                margin-left: 15px;
                
            }
        }
    }

    .row__column {
        max-width: 500px;
        padding: 5px;
        border-bottom: 1px solid var(--week-color-bg);
        border-radius: 5px;
        font-style: italic;
    }
    ul {
        list-style: none;
        display: initial;
        margin-left: 3px;
        li {
            display: inline-block;
            padding-right: 3px;
        }
    }
}