@import '../../styles/variables.scss';

.MainMenu {
    &__link {
        display: block;
        padding: 15px 7px;
        text-decoration: none;
        color: $font_color;
        font-weight: 400;
        margin-bottom: 3px;
        padding-left: 10px;
        margin-left: 3px;
        border-bottom-left-radius: 17px;
        border-top-left-radius: 10px;
        background: $side_bg_color;

        i {
            padding-right: 11px;
            padding-left: 5px;
            color: forestgreen;
        }
    }

    &__overview-overview, &__amazon_tools-amazon_tools, &__account-account, &__inventory-inventory, &__messages-messages {
        border: 1px solid var(--week-color-bg);
        border-right: 0px;
        background-color: var(--bg-color);
    }
}


/* Phones and Tablets Sizes */ 
@media only screen and (max-width : 600px) {
    .MainMenu {
        display: table;
        width: 100%;
        &__link {
            display: table-cell;
            padding: 17px 10px;
            text-decoration: none;
            font-weight: 400;
            padding-left: 10px;
            margin-left: 3px;
            border-radius: 0px;
            border-right: 1px solid #ffffff;
            border-top: 1px solid #ffffff;
            border-left: 1px solid #ffffff;
            text-align: center;

            span {
                font-size: 0.8em;
            }
    
            i {
                padding-right: 11px;
                padding-left: 5px;
                display: block;
                padding-bottom: 1.5px;
            }
        }
        

        &__link:after {
            border: 1px solid gold;
            padding: 3px;
            background-color: aqua;
        }

    }
}