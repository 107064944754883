@import '../../styles/variables.scss';

.ButtonSubmit {
    padding: 8px;
    padding-left: 11px;
    border-radius: 2px;
    padding-right: 11px;

    i {
        padding: 3px;
    }
}
.ButtonSubmit:hover {
    background: $button_hover_color;
}

.Button_wait {
    opacity: 0.3;
    cursor: not-allowed;
}

.Button_notready {
    background: $week_color_bg;
    border: 1px solid $button_border_color;
    color: $week_color;
    cursor: help;
}
.Button_notready:hover {
    background: none;
}