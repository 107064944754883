.PopUp {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    right: 0;
    z-index: 5;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    margin: 0px auto;
    margin-top: 3vh;
    background: #e9e9e9;
    border: 1px solid gainsboro;
    box-shadow: 0px 0px 100px -11px black;
    border-radius: 5px;

    div {

        h2 {
            font-size: medium;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 7px 17px;
        }
    
        ul {
            list-style: none;
            text-align: left;
            margin: 5px 57px;
            line-height: 1.9;
            li::before {
                content: "\2022";  
                color: red; /* Change the color */
                font-weight: bold;
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
        }

    }
}