@import '../../styles/variables.scss';
.TextBox {
    padding: 3px;
    margin: 0;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    
    label {
        display: flex;
        padding: 3px 9px;
        max-width: 95px;
        border-left: 1px solid var(--form-box-border-color);
        min-width: 133px;
        background: var(--week-color-bg);
        flex: 1;
        align-items: center;
        font-weight: 400;
        border-radius: 0;
        font-size: 95%;
    }
    input {
        background: $form_box_bg_color;
        padding: 0.6em;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $form_box_border_color;
        color: $form_box_color;
        min-width: 55px;
    }

    i.fa {
        position: absolute;
        right: 0;
        margin: 1px;
        margin-right: 4px;
        cursor: pointer;
        background: #efecec3d;
        padding: 8.5px;
    }
}
