.Logo {
    padding: 0;
    margin: 0;
    padding-top: 15px;    
    padding-bottom: 17px;
    
    img {
        width: 150px;
        display: block;
        margin: 0px auto;
        margin-top: 2px;
    }
}
