.Menu {
    height: 100%;
    width: 185px;
    position: fixed !important;
    z-index: 1;
    overflow: auto;
    background-color: #ffffff;
    top: 0;
    &__nav {
        display: flex;
        flex-direction: column;
        height: 100vh;
        &__version {
            padding: 9px 13px;
            font-size: x-small;
            display: flex;
            flex: 1;
            align-items: flex-end;
            color: gray;
        }
    }
}

.dark .Menu {
    background-color: #292929;
}

/* Phones and Tablets Sizes */ 
@media only screen and (max-width : 600px) {
    .Menu {   
        height: auto;
        width: 100%;
        bottom: 0;
        max-height: 85px;
        top: auto;

        .Logo {
            display: none;
        }
    }
}