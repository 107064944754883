.Copy {
    padding: 3px;
    margin: 0;
    cursor: copy;    
    position: relative;
    display: inline-block;

    &__tooltiptext {
        visibility: hidden;
        width: 140px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -75px;
        opacity: 0;
        transition: opacity 0.3s;

        &::after {            
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
        }
    }
    
    &:hover &__tooltiptext {
        visibility: visible;
        opacity: 1;
    }

}
