@import '../../styles/variables.scss';

.Selection {
    padding: 3px;
    margin: 0;
    margin-bottom: 15px;
    display: flex;
    
    label {
        display: flex;
        padding: 3px 9px;
        max-width: 95px;
        border-left: 1px solid var(--form-box-border-color);
        min-width: 133px;
        background: var(--week-color-bg);
        flex: 1;
        align-items: center;
        font-weight: 400;
        border-radius: 0;
        font-size: 95%;
    }
    select {
        padding: 0.6em;
        width: 100%;
        box-sizing: border-box;
        background: $form_box_bg_color;
        color: $form_box_color;
        border-color: $border_color;

        option {
            background: $form_box_bg_color;
        }
    }
}
