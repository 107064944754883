@import '../../styles/global.scss';
@import '../../styles/variables.scss';

.Settings {
    padding: 3px;

    &__section {
        @extend .g_section;
        
        &__mainlink, h2 {
            font-size: 1.1em;
            font-weight: bold;
            display: block;
            color: var(--font-color);
            background: var(--side-bg-color);
            padding: 9px;
            margin-left: 15px;
            margin-top: -9px;

            i:first-child{
                margin-right: 5px;
            }
            i:last-child{
                margin-left: 5px;
                text-align: right;
                flex: 30% 1;
                float: right;
                margin-right: 15px;
            }
        }
    }

    &__inner {
        margin: 15px 15px;
    }

    &__download {
        text-align: center;
        i {
            margin: 5px;
            cursor: pointer;
        }
    }
}
