.Estimator {
    margin: 25px 15px;

    &__result {
        font-weight: bold;
        font-size: x-large;
        padding: 19px 19px;
        border: 1px solid;
        border-radius: 10px;
        margin-bottom: 10px;

        p {
            font-size: medium;
            margin-top: 10px;
            font-weight: 500;
        }
    }

    form {
        margin-top: 15px;
        label {
            max-width: 100% !important;
            font-weight: bold;
        }

        input[type="text"], select {
            border: 1px solid;
            padding: 10px;
        }
    }
}

@media only screen and (max-width : 600px) {
    .Estimator {    
        form {
            margin-bottom: 65px;
        }
    }
  }