@import './variables.scss';

.g_section {
    padding: 10px 0px;
    flex: 100%;
    width: 100%;
    margin-right: 14px;
    margin-bottom: 10px;
    border-top: 1px solid $contents_border_color;
    border-radius: 1px;    
}

.g_button, .g_button_dim, .g_button_green {
    padding: 7px 13px;
    border-radius: 1px;
    font-size: 0.9em;
    cursor: pointer;
    text-align: center;
}

.g_button {
    background: var(--link-color);
    border: 1px solid #8080804a;
    color: var(--light-color);
}
.g_button:hover {
    background: $hover;
    color: $hover_color;
}
.g_button_green {
    background: $hover;
    color: $hover_color;
    &:hover {
        background: var(--link-color);
        border: 1px solid #8080804a;
        color: var(--light-color);
    }
}
.g_error_line {
    border: 1px solid #ff00007d;
    border-radius: 3px;
}

.g_red {
    color: #ff00007d;
}

.g_button_dim {
    background: $side_bg_color;
    border: 1px solid #8080804a;
}
.g_button_dim:hover {
    background: $hover;
    color: white;
}

.g_box {
    padding: 15px;
    background: var(--light-color);
    border-radius: 3px;
    box-shadow: 0px 1px 1px 0px var(--week-color);
}


.g_link {
    color: var(--link-color);
    border-bottom: 1px solid #8080804a;
    cursor: pointer;
}

.g_box_note {
    font-size: small;
    padding: 7px;
    background: cornsilk;
    padding-left: 15px;
    border-radius: 1px;
    box-shadow: 0px 5px 5px -5px black;
}