@import '../../../styles/global.scss';
@import '../../../styles/variables.scss';

.Research {
    margin: 25px 15px;
    display: flex;

    &__results, &__filters {
        background: $form_box_bg_color;
        border: 1px solid $border-color;
    }

    &__filters {
        flex: 30%;
        margin: 9px;
        margin-left: 0px;
        padding: 15px;

        h3 {
            margin: 20px 0px 5px 5px;
            font-size: small;
        }

        &__toggle {
            padding: 5px;
            float: right;
            margin-top: -10px;
        }

        &__list, .Selection {
            clear: both;
            ul {
                display: flow-root;
            }

            label {
                padding-top: 8px;
                min-width: 150px;
                &:after {
                    content: "\27A4";
                    padding-left: 3px;
                    font-size: 13px;
                    vertical-align: middle;
                }
            }
        }

        &__top {
            display: flex;
            &__filter {
                flex: 50%;
                padding-top: 7px;
                font-weight: 700;
            }
            &__clear {
                flex: 50%;
                max-width: 80px;
            }
        }

        &__group {
            .group_item {
                display: flex;

                label {
                    &:after {
                        content: ":";
                        padding-left: 3px;
                    }
                }
            }
        }
    }

    &__results {    
        flex: 70%;
        margin: 9px;
        border-radius: 3px;
        padding: 20px;
    }
}


/* Small screen computer or max 1000px tablet */ 
@media only screen and (max-width : 1000px) {
    .Research {
        display: flex;
    
        &__filters {
            margin-bottom: 5px;
        }
    
        &__results {    
            flex: 70%;
        }
    }
}

/* Phones and small Tablets Sizes */ 
@media only screen and (max-width : 600px) {
    .Research {
        display: block;
    
        &__filters {
            margin-bottom: 5px;
            display: block;    
        }
    
        &__results {    
            display: block;
        }
    }
}