.Reset {
    padding: 15px;

    &__title {
        font-size: 1.5em;
        margin-bottom: 9px;
        text-transform: uppercase;
    }

    p {
        line-height: 1.9;
    }

    &__subtitle {
        margin-bottom: 15px;
        font-size: 0.8em;
    }

    &__bottomtext {
        padding: 5px;
        font-size: 0.9em;
        margin-top: 25px;
        border-top: 1px solid gray;
        p {
            a {
                margin-left: 3px;                
            }
        }
    }

    &__done {
        background-color: #cbdacb;
        padding: 7px 15px;
        color: green;
        border-radius: 3px;
    }
}