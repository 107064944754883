.Package {
    padding: 0px;
    &__container {
        &__header {
            text-align: center;
            background-color: white;
            padding: 13px 5px;
            padding-bottom: 17px;
            margin-top: -10px;
            margin-left: -13px;
            margin-right: -13px;

            .Icon {
                position: relative;
                height: 99px;
                overflow: hidden;
                margin-bottom: 15px;
                border-bottom: 3px solid #5eb55e;
                margin-top: -9px;
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    width: 100%;
                }
            }
        }
    }

    .license {
        &__area {
            padding: 1px;
        }
        &__msg {
            margin-top: 15px;
            p.small {
                padding-top: 9px;
                border-top: 1px solid gray;
                margin: 5px;
                margin-top: 29px;
            }
        }
        &__ok {
            text-align: left;
            padding: 7px 15px;
            background: #a7d5a7;
            margin: 9px;
            border-radius: 3px;
            border: 1px solid #98c098;
            color: green;
            display: flex;
            span {
                flex: 1;
                align-self: center;
            }

            i {
                padding: 5px;
                font-size: x-large;
                align-self: center;
                margin-right: 11px;
            }
        }

        &__details {
            padding: 1px;
            margin: 10px;
            h2 {
                font-size: large;
                padding: 3px;
                border-bottom: 1px solid #8ca78c8a;
                margin-bottom: 15px;
                padding-bottom: 7px;
            }
            &__info {
                display: flex;
                span {
                    flex: 1;
                    text-transform: capitalize;

                    &:first-child {
                        flex: 0.5;
                    }
                }

                &__note {
                    font-size: small;
                    padding: 7px;
                    background: cornsilk;
                    padding-left: 15px;
                    border-radius: 1px;
                    box-shadow: 0px 5px 5px -5px black;
                }
            }
        }
    }

    .section {
        margin-top: 13px;
    }
    .actions {
        padding: 3px;
        border-bottom: 1px solid var(--button-border-color);
        padding-bottom: 9px;
        border-radius: 5px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 3px;
        span {
            margin: 1px;
        }

        div, a {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .tabarea {
        &__notselected {
            padding: 0px 10px;
        }
    }
    
    .downloads {
        display: flex;
        .download {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            padding: 19px 15px;
            background: var(--light-color);
            margin: 9px;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
            span {
                display: block;
            }
            &__title {
                font-weight: bold;
            }

            &:hover {
                opacity: 0.7;
            }

            &__info {
                font-size: 81%;
                font-style: italic;
                padding: 5px;
                text-align: left;
                margin-bottom: 5px;
                flex: 1;
            }

            &__icon {
                font-size: 150%;
                display: flex;
                justify-content: center;
                margin-top: 5px;
            }
        }

    }

    .paymenttab_pay, .download_download, .upgrade_upgrade, .license_license_detail, .paymenttab_payment_processing, .paymenttab_license_detail, .paymenttab_upgrade{
        background: var(--week-color);
    }

    .center-screen {
        min-height: 45vh;
        font-size: 95%;
        color: var(--week-color);
        flex: 1;
    }

    .pay {
        h3{
            margin-bottom: 15px;
        }
        &__license { 
            display: flex;
            flex-wrap: wrap;
            &__cart {
                flex: 1;
                padding: 5px 19px;
                background-color: white;
                margin: 5px;
                margin-left: 9px;
                border-radius: 5px;
                border: 1px solid gainsboro;

                &__empty {
                    display: flex;
                    height: 100%;
                    align-items: center;
                }

                &__action {
                    margin-top: 15px;
                }

                h3 {
                    margin: 5px;
                    border-bottom: 1px solid #8080806b;
                    padding-bottom: 5px;
                    margin-bottom: 19px;
                    color: black;
                }

                &__row {
                    display: flex;
                    flex-wrap: wrap;
                    &__label, &__value {
                        padding: 3px;  
                        flex: 1;
                        align-self: center;
                        white-space: nowrap;
                    }
                    &__label {
                        margin-right: 5px;
                    }
                }
            }
            &__plans {
                flex: 1;
                &__plan {
                    flex: 1;
                    padding: 10px;
                    margin: 3px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-radius: 5px;
                    cursor: pointer;
    
                    ._row {
                        display: flex;
                        margin: 3px 2px;
                        span {
                            flex: 1;
                            text-align: left;
                            white-space: nowrap;
                        }
                        span {
                            text-align: right;
                            margin-left: 15px;
                            text-transform: capitalize;
                            min-width: 85px;
                        }
    
                        .attention {
                            font-weight: bold;
                            color: black;
                        }
                    }
                }

            }
        }
    }
}