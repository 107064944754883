.PaymentStatus {
    &__result { 
        width: 95%;
        max-width: 500px;
        &__default {
            padding: 5px;
            border-radius: 3px;         
        }
        &__succeeded {    
            background: #b1c995;
        }
    }
}