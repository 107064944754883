@import '../../styles/variables.scss';

.Header {
    padding: 14px 17px;
    display: flex;
    flex-wrap: wrap;
    background: var(--light-color);
    border-bottom: 1px solid var(--week-color-bg);

    &__alerts {
        &__exist {
            color: red;
            cursor: pointer;
            position: relative;
            margin-right: 10px;
            span {
                font-size: small;
                padding-left: 1px;
                margin-top: -5px;
                position: absolute;
            }
        }
        &__notexist {
            margin-right: 10px;            
        }
        &__open {
            display: none;
        }
    }

    &__logged {
        position: relative;
        &__open {
            position: absolute;
            z-index: 2;
            right: 0;            
            display: flex;
            flex-flow: column;
            background: #aac3aa;
            margin-top: 7px;
            padding: 5px 25px;
            border-radius: 3px;
            display: none;

            div {
                flex-basis: fit-content;
                flex-flow: nowrap;
                display: flex;
                min-width: max-content;
                font-size: small;
                margin-top: 5px;
                strong {
                    padding-right: 3px;
                }
            }

            a {
                display: block;
                border-top: 1px solid #799379;
                margin-top: 13px;
                padding-top: 5px;
                font-weight: bold;
            }
        }

        &:hover &__open {
            display: block;
        }
    }

    &__title, &__alerts, &__status {
        flex: 1;
        text-align: right;
        padding-right: 15px;
    }

    &__logo {
        display: none;
        padding: 0px;
        margin-top: -17px;
        margin-left: -10px;
        margin-right: 7px;
        margin-bottom: -21px;
    }
    
    &__status {
        flex: none;
        padding-right: 3px;
        margin-bottom: -13px;
    }
    &__notlogged {
        text-align: right;
        padding: 17px;
    }
    a {
        color: $link_color;
    }
    &__title {
        font-weight: 500;
        color: var(--title-color);
        text-align: left;
        overflow: hidden;
        max-height: 27px;
    }
}

/* Phones and Tablets Sizes */ 
@media only screen and (max-width : 600px) {
    .Header {    
        &__logo {
            display: flex;
        }
    }
}