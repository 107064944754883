* {margin: 0px;padding: 0px;}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Mulish,Avenir Next,Trebuchet MS,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
    font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
body::-webkit-scrollbar {width: 0.5em;} 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 3px #8080802b; 
    border-radius: 5px;
} 
body::-webkit-scrollbar-thumb {
    background-color: #588133;
    border-radius: 6px;
}
body::-webkit-scrollbar-thumb:hover {background: #109eda; }

a {outline: none; text-decoration: none; color: #2196F3;}
a:focus { outline: none; }

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 65vh;
  flex-direction: column;
}

.left-contents {
  display: block;
  margin-left: 10px;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

h2, h1, a, .Button {
  @extend .prevent-select;
}

/** Spacer Margings **/
.space1 {margin: 1px;}.space2 {margin: 2px;}.space3 {margin: 3px;}.space5 {margin: 5px;}.space10 {margin: 10px;}

.spaceL-1 {margin-left: -1px;}.spaceL-2 {margin-left: -2px;}.spaceL-3 {margin-left: -3px;}.spaceL-5 {margin-left: -5px;}.spaceL-10 {margin-left: -10px;}
.spaceR-1 {margin-right: -1px;}.spaceR-2 {margin-right: -2px;}.spaceR-3 {margin-right: -3px;}.spaceR-5 {margin-right: -5px;}.spaceR-10 {margin-right: -10px;}
.spaceT-1 {margin-top: -1px;}.spaceT-2 {margin-top: -2px;}.spaceT-3 {margin-top: -3px;}.spaceT-5 {margin-top: -5px;}.spaceT-10 {margin-top: -10px;}
.spaceB-1 {margin-bottom: -1px;}.spaceB-2 {margin-bottom: -2px;}.spaceB-3 {margin-bottom: -3px;}.spaceB-5 {margin-bottom: -5px;}.spaceB-10 {margin-bottom: -10px;}

.spaceL1 {margin-left: 1px;}.spaceL2 {margin-left: 2px;}.spaceL3 {margin-left: 3px;}.spaceL5 {margin-left: 5px;}.spaceL10 {margin-left: 10px;}
.spaceR1 {margin-right: 1px;}.spaceR2 {margin-right: 2px;}.spaceR3 {margin-right: 3px;}.spaceR5 {margin-right: 5px;}.spaceR10 {margin-right: 10px;}
.spaceT1 {margin-top: 1px;}.spaceT2 {margin-top: 2px;}.spaceT3 {margin-top: 3px;}.spaceT5 {margin-top: 5px;}.spaceT10 {margin-top: 10px;}
.spaceB1 {margin-bottom: 1px;}.spaceB2 {margin-bottom: 2px;}.spaceB3 {margin-bottom: 3px;}.spaceB5 {margin-bottom: 5px;}.spaceB10 {margin-bottom: 10px;}

.font-xx-s {font-size: xx-small;}.font-x-s {font-size: x-small;}.font-s {font-size: small;}.font-smaller {font-size: smaller;}.font-m {font-size: medium;}.font-large {font-size: large;}.font-larger {font-size: larger;}

/**END of Spacer*/

.row {
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
  &__column {
    flex: 1;
  }
  &__none {
    flex: none;
    padding-right: 11px;
  }
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.blinking {
  animation: blinking 1s infinite;
}

/* Specifies the animation and transparency for the blinking text */
@keyframes blinking {
  65% {
      opacity: .50;
  }
  85% {
      opacity: .75;
  }
  100% {
      opacity: 1;
  }
}

.light {
  background-color: #f5fff5;;
}

.dark {
  background-color: #0f0f0f;
}

.line{
  padding: 3px;
  border-bottom: 1px solid #80808021;
  margin: 1px 1px 7px 1px;
}

.bold {
  font-weight: bold;
}

.blue {
  color: #2196F3;
}

.small {
  font-size: small;
}

.no_bg {
  background-color: transparent !important;
  border: 0 !important;
}

.ptitle {
  font-size: 1.5em;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.psubtitle {
  font-family: Montserrat,sans-serif;
  line-height: 1.55;
  font-size: 15px;
}

._data {
  display: flex;
  margin-bottom: 3px;
  align-items: center;
  label {
    padding: 5px;
    flex: 0.5;
    text-align: right;
    background: var(--week-color-bg);
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    margin-right: 5px;
    font-size: 85%;
  }
  span {
    padding: 5px;
    flex: 1;
    text-align: left;
  }
}

/** Map of custom Icons & Images */
@mixin icon_map_image {
  content: " ";
  background-image: url("../assets/images/images_map.png");
  position: absolute;    
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
@mixin icon_map_parent {
  display: inline-block;
  position: relative;
  width: 197px;
  height: 58px;
}
@mixin icon_countries {
  display: inline-block;
  position: relative;
  width: 38px;
  height: 36px;
  vertical-align: middle;
}
.icon_e:hover {
  opacity: 0.7;
}
.icon-app-store {
  @include icon_map_parent;
  &:after {
    @include icon_map_image;
    background-position: -8px -9px;;
  }
}
.icon-android-store {
  @include icon_map_parent;
  &:after {
    @include icon_map_image;
    background-position: -8px -9px;
  }
}
.icon-us {
  @include icon_countries;
  &:after {
    @include icon_map_image;
    background-position: -60px -161px;
  }
}
.icon-uk {
  @include icon_countries;
  &:after {
    @include icon_map_image;
    background-position: -3px -161px;
  }
}
.icon-de {
  @include icon_countries;
  &:after {
    @include icon_map_image;
    background-position: -177px -161px;
  }
}
.icon-fr {
  @include icon_countries;
  &:after {
    @include icon_map_image;
    background-position: -118px -161px;
  }
}
.icon-it {
  @include icon_countries;
  &:after {
    @include icon_map_image;
    background-position: -350px -161px;
  }
}
.icon-sp {
  @include icon_countries;
  &:after {
    @include icon_map_image;
    background-position: -236px -161px;
  }
}
.icon-ca {
  @include icon_countries;
  &:after {
    @include icon_map_image;
    background-position: -293px -219px;
  }
}

body.dark {

  .icon-app-store {
    &:after {
      background-position: -248px 217px;
    }
  }
  .icon-android-store {
    &:after {
      background-position: -8px -9px;
    }
  }

}
/*END Images Map*/

.Page {
  margin-bottom: 5px;
  padding: 15px !important;
}
@media only screen and (max-width : 600px) {
  .left-contents {
    margin-left: 0px;
  }
  .Page {
    margin-bottom: 73px !important;
  }
  
  .center-screen {
    min-height: 29vh !important;
  }
}