.ConfirmWindow {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    right: 0;
    z-index: 5;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    margin: 0px auto;
    margin-top: 1vh;
    background: #e9e9e9;
    border: 1px solid gainsboro;
    box-shadow: 0px 0px 100px -11px black;
    border-radius: 5px;

    h2 {
        font-size: medium;
        background-color: gray;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: whitesmoke;
        padding: 7px 17px;
    }

    p {
        margin-top: 17px;
        padding: 7px 17px;
        text-align: center;
    }

    &__action { 
        padding: 5px;
        margin-top: 15px;
        display: flex;
        &__ok, &__cancel {
            flex: 1;
            margin: 3px
        }
        &__ok {
            background: rgb(66, 88, 65);
            color: whitesmoke;
        }
        &__cancel {
            background: rgb(168, 83, 83);
            color: whitesmoke;
        }
    }
}