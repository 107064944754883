.CheckoutForm {
    padding: 15px;

    h3 {
        text-align: center;
    }

    &__subtitle {
        text-align: left;
    }

    &__section {
        button {
            text-align: center;
            margin-top: 15px;
            margin-bottom: 9px;
        }

        &__group {
            margin-left: 15px;    
            .g_link {
                margin: 3px;
            }

        }

    }
}