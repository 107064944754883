.LoginPage {
    padding: 15px;

    &__title {
        font-size: 1.5em;
        margin-bottom: 9px;
        text-transform: uppercase;
    }

    &__subtitle {
        margin-bottom: 15px;
        font-size: 0.8em;
    }

    &__bottomtext {
        padding: 5px;
        font-size: 0.9em;
        p {
            a {
                margin-left: 15px;
                
            }
        }
    }
}