@import '../../styles/variables.scss';

.Button {
    padding: 5px;
    background: $button_color;
    border: 1px solid $button_border_color;
    border-radius: 2px;
    cursor: pointer;
    color: $font_color;

    i {
        padding: 3px;
    }
    span {
        padding-left: 3px;
        padding-right: 3px;
    }
}
.Button:hover {
    background: $button_hover_color;
}

.Button_wait {
    opacity: 0.3;
    cursor: not-allowed;
}

.Button_notready {
    background: $week_color_bg;
    border: 1px solid $button_border_color;
    color: $week_color;
    cursor: help;
}
.Button_notready:hover {
    background: none;
}